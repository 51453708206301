import React from "react";

import styles from "./NetAPY.module.sass";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, Label } from "recharts";
import {
    formatDecimals,
    _no,
    _notEmpty,
    displayWithLoader
} from "../../../../utils";
import { MAIN_COLOR_A, MAIN_COLOR_B } from "../../../../utils/config";
import { useSelector } from "react-redux";

// B5E4CA
const COLORS = [MAIN_COLOR_A, MAIN_COLOR_B, '#f99191'];
const DEFAULT = ["#1A1D1F"];

const NetAPY = ({ lendingAPY, borrowAPY, isFetching }) => {

    let netAPY = useSelector((state) => {
        return state.settings.netAPY;
    });

    // Always use the prop passed from parent component to ensure consistency with Compact View
    netAPY = netAPY || 0;

    const supply = parseFloat(formatDecimals(lendingAPY));
    const borrow = parseFloat(formatDecimals(borrowAPY));

    const hasLendingData = _notEmpty(supply) || _notEmpty(borrow);

    const data = [
        { name: "Supply APY", value: supply },
        { name: "Borrow APY", value: borrow },
        { name: "Points APY", value: 0 },
    ];

    const defaultData = [
        {
            name: "APY",
            value: 100
        }
    ];

    const ToolTipContent = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;

            if (_no(hasLendingData)) return null;

            return (
                <div className={styles.tooltip}>
                    <p className={styles.label}>{`${data.name}: ${data.value}%`}</p>
                </div>
            );
        }

        return null;
    };

    const pieColors = hasLendingData
        ? COLORS
        : DEFAULT;

    const pieData = hasLendingData
        ? data
        : defaultData;

    return (
        <div className={styles.pie}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Tooltip
                        contentStyle={{

                        }}
                        labelStyle={{}}
                        itemStyle={{

                        }}
                        content={<ToolTipContent />}
                    />
                    <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        height={100}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={80}
                        outerRadius={90}
                        fill="#8884d8"
                        dataKey="value"
                        paddingAngle={0}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={pieColors[index % pieColors.length]}
                            />
                        ))}
                        <Label
                            value={isFetching ? "Loading" : "Net APY"}
                            position="center"
                            style={{
                                fontSize: "20px",
                                fontWeight: 500,
                                fill: "#6F767E"
                            }}
                            dy={-30}
                        />
                        <Label
                            value={displayWithLoader(isFetching, `${netAPY > 0 ? '+' : ''}${formatDecimals(netAPY)}%`)}
                            position="center"
                            style={{
                                fontSize: "24px",
                                fontWeight: 500,
                                fill: "#FFF"
                            }}
                            dy={5}
                        />
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default NetAPY;
